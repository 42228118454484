<template>
  <v-navigation-drawer
    class="main-nav"
    v-model="settings.navOpen"
    app>
    <v-list>
      <template
        v-for="(section, i) in navItems"
        :key="`section-${i}`">
        <navigation-section
          :title="section.title"
          :role="section.role"
          :permission="section.permission">
          <navigation-item
            v-for="(item, j) in section.items"
            :key="`item-${j}`"
            :title="item.title"
            :icon="item.icon"
            :to="item.to"
            :role="item.role"
            :permission="item.permission" />
        </navigation-section>
        <v-divider
          v-if="i !== navItems.length - 1"
          class="mt-3 mb-3" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useSettingsStore } from '@core/stores/settings'
import { UserRoles } from '@auth/models/user'
import NavigationSection from '@ter/components/Navigation/NavigationSection.vue'
import NavigationItem from '@ter/components/Navigation/NavigationItem.vue'
import { NavigationSection as NavSection } from '@ter/types/navigation'

export default defineComponent({
  name: 'Navigation',
  components: {
    NavigationItem,
    NavigationSection
  },
  setup() {
    const store = useSettingsStore()
    const settings = store.getSettings
    const navItems: NavSection[] = [
      {
        title: 'navigation.userSpace',
        role: UserRoles.USER,
        permission: undefined as string,
        items: [
          { title: 'pages.index', icon: 'mdi-home', to: { name: 'index' }, permission: 'user.permission' },
          { title: 'pages.fills', icon: 'mdi-note-edit', to: { name: 'projects' }, permission: 'user.permission' }
        ]
      },
      {
        title: 'navigation.managerSpace',
        role: UserRoles.WORKGROUP_ADMIN,
        permission: undefined as string,
        items: [
          { title: 'pages.pillars', icon: 'mdi-folder', to: { name: 'pillars' }, permission: 'admin.permission'},
          { title: 'pages.workgroups', icon: 'mdi-account-group', to: { name: 'workgroups' }, permission: 'chief.permission' }
        ]
      },
      {
        title: 'navigation.adminSpace',
        role: UserRoles.ADMIN,
        permission: undefined as string,
        items: [
          { title: 'pages.admin.users', icon: 'mdi-account', to: { name: 'admin.users' }, permission: 'admin.permission' },
          { title: 'pages.admin.projects', icon: 'mdi-folder', to: { name: 'admin_projects' }, permission: 'admin.permission' },
          { title: 'pages.admin.workgroups', icon: 'mdi-account-group', to: { name: 'admin.workgroup' }, permission: 'admin.permission' },
          { title: 'pages.admin.import', icon: 'mdi-cloud-upload', to: { name: 'import' }, permission: 'admin.permission' }
        ]
      }
    ]

    return {
      settings,
      navItems
    }
  }
})
</script>

<style lang="scss">
.main-nav {
  .v-list-item__prepend > .v-icon {
    margin-inline-end: 16px;
  }
}
</style>
