import { createRouter as createVueRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

export const createRouter = (routes: RouteRecordRaw[]) => {
  return createVueRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes,
    linkActiveClass: 'link-active',
    linkExactActiveClass: 'link-active--exact'
  })
}

declare module 'vue-router' {
  interface RouteMeta {
    permission?: string
    layout?: string | unknown
  }
}
