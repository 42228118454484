import { ApiPlugin } from '@/services/api/types/plugin.types'
import loggerPlugin from '@/services/api/plugins/logger.plugin'
import apiResponsePlugin from '@/services/api/plugins/api-response.plugin'
import halPlugin from '@/services/api/plugins/hal.plugin'
import authPlugin from '@auth/api/plugins/auth.plugin'
import errorNotificationPlugin from '@core/api/plugins/error-notification.plugin'

const plugins: ApiPlugin[] = [apiResponsePlugin, halPlugin, authPlugin, errorNotificationPlugin, loggerPlugin]

export default plugins
