import { defineStore } from 'pinia'
import { api } from '@/services'

export const useOptionsStore = defineStore('optionsStore', {
  state: () => ({
    options: {
      locales: [],
      dictionaries: {},
      indexContent: ''
    },
    fetched: false
  }),
  getters: {
    getLocales: (state) => () => state.options.locales,
    getDictionaries: (state) => () => state.options.dictionaries,
    getIndexContent: (state) => () => state.options.indexContent,
    isFetched: (state) => () => state.fetched
  },
  actions: {
    async fetchOptions() {
      if (this.fetched) {
        return this.fetched
      }

      try {
        const response = await api.modules.options.get()
        this.options = { ...this.options, ...response.data }
        this.fetched = true
      } catch (error) {
        // nothing to do
      }

      return this.fetched
    }
  }
})
