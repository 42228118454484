import { defineStore } from 'pinia'
import { Notification, NotificationType } from '@core/types/notification'
import { ApiError } from '@/services/api/types/api.types'
import appConfig from '@/config/app.config'

const CUSTOM_MESSAGE_MAP = {
  'Network Error': 'error.networkError',
  'Failed Validation': 'error.validationError'
}

export const useNotificationStore = defineStore('notificationStore', {
  state: () => ({
    notifications: [] as Notification[]
  }),
  getters: {
    getNotifications: (state) => () => state.notifications,
    getNotificationsCount: (state) => () => state.notifications.length,
    getNextNotification: (state) => (): Notification | undefined => {
      return state.notifications.shift()
    }
  },
  actions: {
    addNotification(notification: Notification) {
      notification.timeout = notification.timeout || appConfig.notificationsTimeout
      notification.position = notification.position || appConfig.notificationsPosition
      notification.open = false
      this.notifications.push(notification)
    },
    addInfo(message: string, translate = false) {
      this.addNotification({
        type: NotificationType.INFO,
        message: message,
        translate: translate
      })
    },
    addSuccess(message: string, translate = false) {
      this.addNotification({
        type: NotificationType.SUCCESS,
        message: message,
        translate: translate
      })
    },
    addWarning(message: string, translate = false) {
      this.addNotification({
        type: NotificationType.WARNING,
        message: message,
        translate: translate
      })
    },
    addError(message: string, translate = false) {
      this.addNotification({
        type: NotificationType.ERROR,
        message: message,
        translate: translate
      })
    },
    addException(e: unknown, translate = false) {
      let message = 'error.unknownError'

      if (typeof e === 'object') {
        message = (((e as ApiError).data?.detail || (e as ApiError).statusText || (e as ApiError).message) as string) || message
      } else {
        message = typeof e === 'string' ? e : message
      }

      if (Object.keys(CUSTOM_MESSAGE_MAP).includes(message)) {
        message = CUSTOM_MESSAGE_MAP[message as never]
      }

      this.addError(message, translate)
    }
  }
})
