import { RouteRecordRaw } from 'vue-router'
import MainLayout from '@ter/layouts/MainLayout.vue'

export enum Routes {
  PROJECTS = 'projects',
  RESULTS = 'project_results',
  ADMIN_PROJECTS = 'admin_projects'
}

export default [
  {
    path: '/projects',
    name: Routes.PROJECTS,
    component: () => import('../pages/ProjectsPage.vue'),
    meta: {
      permission: 'user.permission',
      layout: MainLayout
    }
  },
  {
    path: '/projects/:project_id/results',
    name: Routes.RESULTS,
    props: true,
    component: () => import('../pages/ProjectResult.vue'),
    meta: {
      permission: 'user.permission',
      layout: MainLayout
    }
  },
  {
    path: '/admin/projects',
    name: Routes.ADMIN_PROJECTS,
    component: () => import('../pages/AdminProjectPage.vue'),
    meta: {
      permission: 'admin.permission',
      layout: MainLayout
    }
  }
] as RouteRecordRaw[]
