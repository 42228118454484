import { defineStore } from 'pinia'
import { Settings } from '@core/types/settings'
import { getDefaultLocale } from '@/utils/locale.util'
import { useLocalStorage } from '@vueuse/core'
import appConfig from '@/config/app.config'

export const useSettingsStore = defineStore('settingsStore', {
  state: () => ({
    settings: useLocalStorage('settings', {
      locale: getDefaultLocale(),
      theme: appConfig.defaultTheme,
      navOpen: true
    })
  }),
  getters: {
    getSettings: (state) => state.settings,
    getLocale: (state) => state.settings.locale,
    getTheme: (state) => state.settings.theme,
    getNavOpen: (state) => () => state.settings.navOpen
  },
  actions: {
    setSettings(settings: Partial<Settings>) {
      this.settings = { ...this.settings, ...settings }
    },
    setLocale(locale: string) {
      this.settings.locale = locale
    },
    setTheme(theme: string) {
      this.settings.theme = theme
    },
    setNavOpen(open: boolean) {
      this.settings.navOpen = open
    }
  }
})
