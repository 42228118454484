<template>
  <v-snackbar
    v-if="currentNotification"
    v-model="currentNotification.open"
    :timeout="currentNotification.timeout"
    :multi-line="true"
    location="bottom right"
    variant="text"
    transition="scroll-y-reverse-transition">
    <v-alert
      :type="currentNotification.type"
      @click.prevent="currentNotification.open = false"
      style="cursor: pointer">
      {{ currentNotification.translate ? $t(currentNotification.message, currentNotification.message) : currentNotification.message }}
    </v-alert>
  </v-snackbar>
</template>

<script lang="ts">
import { defineComponent, watch, ref, Ref } from 'vue'
import { useNotificationStore } from '@core/stores/notification'
import { Notification } from '@core/types/notification'

export default defineComponent({
  name: 'Notifications',
  setup() {
    const store = useNotificationStore()
    const currentNotification: Ref<Notification | undefined> = ref(store.getNextNotification())

    if (currentNotification.value) {
      currentNotification.value.open = true
    }

    const setCurrentNotification = (notification: Notification | undefined) => {
      if (notification) {
        notification.open = true
        currentNotification.value = notification
      }
    }

    watch(
      currentNotification,
      () => {
        if (currentNotification.value && currentNotification.value.open === false) {
          setTimeout(() => {
            currentNotification.value = undefined
            setCurrentNotification(store.getNextNotification())
          }, 400)
        }
      },
      { deep: true }
    )

    watch(store.getNotifications(), () => {
      if (!currentNotification.value && store.getNotifications().length) {
        setCurrentNotification(store.getNextNotification())
      }
    })

    return {
      currentNotification
    }
  }
})
</script>
