export default {
  auth: {
    login: 'Bejelentkezés',
    logout: 'Kijelentkezés'
  },

  error: {
    error403:
      'Nincs jogosultságod az oldal betöltéséhez.<br>Visszaléphetsz a kezdőlapra, vagy bejelentkezhetsz egy másik felhasználóval aki rendelkezik a megfelelő jogokkal.'
  }
}
