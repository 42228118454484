import { AxiosInstance, AxiosResponseTransformer } from 'axios'
import { ApiPlugin } from '@/services/api/types/plugin.types'
import { ApiHalResponse, ApiResponse, LinksData, PaginationData } from '@/services/api/types/api.types'

export class HalPlugin extends ApiPlugin {
  initialize(axios: AxiosInstance): void {
    ;(axios.defaults.transformResponse as AxiosResponseTransformer[]).push((data) => {
      return data ? this.normalize(data) : data
    })

    axios.interceptors.response.use(<T>(response: ApiResponse) => {
      const pagination: PaginationData = response.data ? response.data['pagination'] : undefined
      const links: LinksData = response.data ? response.data['links'] : undefined

      if (response.data) {
        delete response.data['pagination']
        delete response.data['links']
      }

      return {
        headers: response.headers,
        status: response.status,
        data: response.data as T,
        pagination: pagination,
        links: links
      } as ApiHalResponse<T>
    })
  }

  normalize(responseData: Record<string, never>) {
    const links: Record<string, never> = {}
    const pagination: Record<string, never> = {}
    const data: Record<string, never> = {}

    for (const key in responseData) {
      switch (key) {
        case '_links': {
          const link = responseData[key] as never
          for (const linkKey of Object.keys(link)) {
            if (linkKey === 'first' || linkKey === 'last') {
              if (!pagination['links']) pagination['links'] = {} as never
              if (!pagination['links'][linkKey]) {
                pagination['links'][linkKey] = {} as never
              }
              pagination['links'][linkKey] = link[linkKey]
            } else {
              links[linkKey] = link[linkKey]
            }
          }
          delete responseData[key]
          break
        }
        case 'page_count':
        case 'page_size':
        case 'total_items':
        case 'page':
          pagination[key] = responseData[key]
          delete responseData[key]
          break
        case '_embedded': {
          const embedded = responseData[key]
          for (const embeddedKey of Object.keys(embedded)) {
            if (Array.isArray(embedded[embeddedKey])) {
              data[embeddedKey] = embedded[embeddedKey]
              for (let i = 0; i < (data[embeddedKey] as []).length; i++) {
                data[embeddedKey][i] = this.normalize(data[embeddedKey][i])
                delete data[embeddedKey][i]['pagination']
                if (data[embeddedKey][i]['links']) {
                  if (!links[embeddedKey]) links[embeddedKey] = [] as never
                  links[embeddedKey][i] = data[embeddedKey][i]['links']
                  delete data[embeddedKey][i]['links']
                }
              }
            } else {
              data[embeddedKey] = this.normalize(embedded[embeddedKey])
              delete data[embeddedKey]['pagination']
              if (data[embeddedKey]['links']) {
                links[embeddedKey] = data[embeddedKey]['links']
                delete data[embeddedKey]['links']
              }
            }
          }
          delete responseData[key]
          break
        }
        default: {
          data[key] = responseData[key]
        }
      }
    }

    if (links && Object.keys(links).length) {
      data['links'] = links as never
    }

    if (pagination && Object.keys(pagination).length) {
      data['pagination'] = pagination as never
    }

    return data as never
  }
}

export default new HalPlugin()
