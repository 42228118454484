import { createI18n as createVueI18n, LocaleMessages, VueMessageType } from 'vue-i18n'

export const createI18n = (locale: string, fallbackLocale: string, messages: LocaleMessages<VueMessageType>) => {
  return createVueI18n({
    legacy: false,
    globalInjection: true,
    locale: locale,
    fallbackLocale: fallbackLocale,
    messages: messages
  })
}
