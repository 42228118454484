export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export type Notification = {
  type: NotificationType
  message: string
  timeout?: number
  position?: string
  open?: boolean
  translate?: boolean
}
