import { Module } from '@/types/module.types'
import locales from './i18n'
import routes from './routes'
import ImportModules from './api'

export default {
  name: 'import',
  routes: routes,
  locales: locales,
  apiModules: ImportModules
} as Module
