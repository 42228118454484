import { Plugin } from 'vue'
import { useSettingsStore } from '@core/stores/settings'
import { i18n } from '@/services'

export const SetInitialLocale: Plugin = {
  install() {
    const store = useSettingsStore()
    i18n.global.locale.value = store.getLocale
  }
}
