import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { User, UserRoles } from '@auth/models/user'
import { createUser } from '@auth/factory/user.factory'
import { api } from '@/services'
import { shallowRef } from 'vue'
import { checkUserPermission, checkUserRole } from '@auth/utils/user.util'

export const useUserStore = defineStore('userStore', {
  state: () => ({
    user: useLocalStorage<User>('auth.user', {} as User),
    token: useLocalStorage<string>('auth.token', null),
    error: shallowRef(null)
  }),
  getters: {
    getUser: (state) => () => state.user,
    getToken: (state) => () => state.token,
    getError: (state) => (): { code?: number; message?: string; component?: unknown } | null => state.error,
    checkUserPermission:
      (state) =>
      (permission: string): boolean => {
        return state.user && checkUserPermission(state.user, permission)
      },
    checkUserRole:
      (state) =>
      (role: UserRoles | UserRoles[], inheritMode = false): boolean => {
        return state.user && checkUserRole(state.user, role, inheritMode)
      }
  },
  actions: {
    setUser(user: Partial<User>) {
      this.user = createUser({ ...(this.user || {}), ...user })
    },
    setToken(token?: string) {
      this.token = token
    },
    clear() {
      this.user = {} as User
      this.token = null
    },
    async fetchUser() {
      if (!this.token) {
        this.clear()
        return this.user
      }

      try {
        const response = await api.modules.auth.me()
        this.setUser(response.data)
      } catch (error) {
        this.clear()
      }

      return this.user
    },
    setError(error?: { code?: number; message?: string; component?: unknown }) {
      this.error = error
    }
  }
})
