<template>
  <error-page
    :code="403"
    message="error.error403">
    <v-btn
      color="primary"
      :to="{ name: 'index' }">
      {{ $t('common.backToIndex') }}
    </v-btn>
    <v-btn @click="logout">
      {{ $t('auth.logout') }}
    </v-btn>
  </error-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ErrorPage from '@core/pages/ErrorPage.vue'
import { useUserStore } from '@/modules/auth/stores/user.store'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Error403Page',
  components: {
    ErrorPage
  },
  setup() {
    const router = useRouter()
    const userStore = useUserStore()
    const logout = () => {
      userStore.clear()
      router.push('/login')
    }

    return {
      logout
    }
  }
})
</script>
