import core from '@/modules/core'
import vuetify from '@/modules/vuetify'
import forms from '@/modules/forms'
import ter from '@/modules/ter'
import auth from '@/modules/auth'
import dimension from '@/modules/dimension'
import projects from '@/modules/projects'
import workgroups from '@/modules/workgroup'
import user from '@/modules/user'
import importModule from '@/modules/import'
import pillars from '@/modules/pillar'

// register modules here
export const modules = {
  core,
  vuetify,
  forms,
  ter,
  auth,
  dimension,
  projects,
  workgroups,
  user,
  importModule,
  pillars
}

// special routes
export const error404Page = () => import('../modules/core/pages/ErrorPage.vue')
