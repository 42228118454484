import { App, inject } from 'vue'
import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import appConfig from '@/config/app.config'
import { ApiModules } from '@/types/api-modules'
import plugins from '@/types/api-plugins'
import { ApiModuleDefinitions } from '@/services/api/types/module.types'
import { ApiResponse } from '@/services/api/types/api.types'

const apiKey = Symbol('vue-cts-api')

export interface ApiOptions {
  axiosConfig?: AxiosRequestConfig
  moduleDefs?: ApiModuleDefinitions
}

export interface Api {
  readonly config: ApiOptions
  readonly modules: ApiModules
  readonly axios: AxiosInstance
  install: (app: App, options: Record<string, unknown>[]) => void
  request<T, D>(config: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  get<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  delete<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  head<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  options<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  post<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  put<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  patch<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  postForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  putForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
  patchForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>>
}

export function createApi(options: ApiOptions): Api {
  // axios
  Axios.defaults.baseURL = appConfig.apiUrl
  Axios.defaults.withCredentials = appConfig.apiCredentials
  Axios.defaults.headers.common = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }

  const axiosConfig = options.axiosConfig ?? {}
  const axios: AxiosInstance = Axios.create(axiosConfig)

  // plugins
  plugins.forEach((plugin) => plugin.initialize(axios))

  // build api
  const modules = {}
  const api = {
    config: options,
    modules: modules as ApiModules,
    axios: axios,
    install(app: App): void {
      app.config.globalProperties.$api = this.modules
      app.provide(apiKey, this.modules)
    },
    request<T, D>(config: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.request(config)
    },
    get<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.get(url, config)
    },
    delete<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.delete(url, config)
    },
    head<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.head(url, config)
    },
    options<T, D>(url: string, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.options(url, config)
    },
    post<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.post(url, data, config)
    },
    put<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.put(url, data, config)
    },
    patch<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.patch(url, data, config)
    },
    postForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.postForm(url, data, config)
    },
    putForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.putForm(url, data, config)
    },
    patchForm<T, D>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<ApiResponse<T>> {
      return this.axios.patchForm(url, data, config)
    }
  }

  // modules
  if (options.moduleDefs) {
    Object.keys(options.moduleDefs).forEach((name) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      modules[name] = new options.moduleDefs[name](api)
    })
  }

  return api
}

export function useApi(): ApiModules {
  return inject(apiKey)
}
