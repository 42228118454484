export function deepAssign(target: Record<string, unknown>, ...sources: Record<string, unknown>[]) {
  for (const source of sources) {
    for (const key in source) {
      const vs = source[key]
      const vt = target[key]

      if (Object(vs) === vs && Object(vt) === vt) {
        target[key] = deepAssign(vt as Record<string, unknown>, vs as Record<string, unknown>)
        continue
      }

      target[key] = source[key]
    }
  }

  return target
}

export function strictMerge<T>(base: T, obj: Record<string, unknown>): T {
  const result = {} as Record<string, unknown>

  Object.keys(base).forEach((key) => {
    if (key in obj) {
      result[key] = obj[key]
    }
  })

  return result as T
}

export function objWithout(obj: unknown, ...without: string[]): Record<string, unknown> {
  const result = { ...(obj as Record<string, never>) }

  for (const w of without) {
    delete result[w]
  }

  return result
}
