import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { ApiPlugin } from '@/services/api/types/plugin.types'
import { useUserStore } from '@auth/stores/user.store'

export class AuthPlugin extends ApiPlugin {
  initialize(axios: AxiosInstance): void {
    axios.interceptors.request.use((config: AxiosRequestConfig) => {
      const store = useUserStore()
      const token = store.getToken()
      //config.headers['XDEBUG_SESSION_START'] = 'PHPSTORM'

      if (token) {
        config.headers['Authorization'] = config.headers['Authorization'] ?? `Bearer ${token}`
      }

      return config
    })
  }
}

export default new AuthPlugin()
