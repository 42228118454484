export default {
  workgroup: {
    actions_cancel: 'Cancel',
    actions_modify: 'Modify',
    field_name: 'Workgroup name',
    field_user_name: 'User name',
    field_status: 'Status',
    field_education: 'Education',
    field_research: 'Research',
    field_talentCare: 'Talent care',
    field_project: 'Project',
    field_sumPoints: 'Sum',
    field_ranking: 'Ranking in workgroup',
    field_projectRanking: 'Ranking in project',
    field_categories: 'Categories',
    field_workgroupPercent: 'Contribution to the workgroup',
    field_point: 'Point',
    field_percent: 'Percent',
    field_points: 'Points',
    field_total_user: 'Total user',
    field_chief: 'Workgroup lead',
    field_workgroup_role: 'Workgroup role',
    tab_user_data: 'User datas',
    tab_results: 'Results',
    tab_person_udertake: 'Personal undertake',
    tab_workgroup_udertake: 'Workgroup undertake',
    tab_dimension_points: 'Dimensions results',
    modal_role: 'Workgroup role change',
    modal_create_user: 'Add user to the workgroup',
    modal_update_user: 'Workgroup user modify',
    user_fill: 'User fill',
    workgroup_data: 'Workgroups data',
    role_colleague: 'Colleague',
    role_chief: 'Chief',
    status: {
      opened: 'Opened',
      notStarted: 'Not started',
      processed: 'Processed',
      closed: 'Closed'
    },
    modal: {
      modify: 'Edit workgroup',
      create: 'Create workgroup'
    }
  }
}
