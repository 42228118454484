import { RouteRecordRaw } from 'vue-router'
import MainLayout from '@ter/layouts/MainLayout.vue'

export enum Routes {
  WORKGROUPS = 'workgroups',
  WORKGROUP = 'workgroup',
  ADMIN_WORKGROUP = 'admin.workgroup',
  WORKGROUP_EDIT = 'workgroup.edit',
  WORKGROUP_USER_FILL = 'workgroup.user_fill'
}

export default [
  {
    path: '/workgroups',
    name: Routes.WORKGROUPS,
    component: () => import('../pages/WorkgroupsPage.vue'),
    meta: {
      permission: 'chief.permission',
      layout: MainLayout
    }
  },
  {
    path: '/workgroups/:workgroup_id',
    name: Routes.WORKGROUP,
    props: true,
    component: () => import('../pages/WorkgroupPage.vue'),
    meta: {
      permission: 'chief.permission',
      layout: MainLayout
    }
  },
  {
    path: '/admin/workgroups',
    name: Routes.ADMIN_WORKGROUP,
    component: () => import('../pages/AdminWorkgroupPage.vue'),
    meta: {
      permission: 'admin.permission',
      layout: MainLayout
    }
  },
  {
    path: '/admin/workgroups/:workgroup_id',
    name: Routes.WORKGROUP_EDIT,
    props: true,
    component: () => import('../pages/EditWorkgroup.vue'),
    meta: {
      permission: 'admin.permission',
      layout: MainLayout
    }
  },
  {
    path: '/workgroups/:workgroup_id/:project_id/user-fill/:user_id',
    name: Routes.WORKGROUP_USER_FILL,
    props: true,
    component: () => import('../pages/WorkgroupUserFill.vue'),
    meta: {
      permission: 'chief.permission',
      layout: MainLayout
    }
  }
] as RouteRecordRaw[]
