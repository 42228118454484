import { App } from 'vue'
import { RouteRecordRaw } from 'vue-router'
import { createStore } from './store'
import { createRouter } from './router'
import { createI18n } from './i18n'
import { createMeta } from './meta'
import { createApi } from './api'
import { deepAssign } from '@/utils/obj.util'
import { getDefaultLocale, getFallbackLocale } from '@/utils/locale.util'
import { ApiModuleDefinitions } from '@/services/api/types/module.types'
import modules from '@/modules'
import { error404Page } from '@/types/modules'
import LoginLayout from '@ter/layouts/LoginLayout.vue'

// gets basic data from modules required for initializing services
function loadModules() {
  Object.values(modules).forEach((module) => {
    if (module.routes) {
      routes = routes.concat(module.routes)
    }

    if (module.locales) {
      deepAssign(messages, module.locales)
    }

    if (module.apiModules) {
      Object.assign(apiModules, module.apiModules)
    }
  })
}

// initializes the modules after every service are installed
function initModules(app: App) {
  Object.values(modules).forEach((module) => {
    if (module.plugins) {
      for (const plugin of module.plugins) {
        app.use(plugin)
      }
    }

    if (module.directives) {
      for (const name in module.directives) {
        app.directive(name, module.directives[name])
      }
    }

    if (module.init !== undefined) {
      module.init(app)
    }
  })
}

let routes: RouteRecordRaw[] = []
routes.push({
  path: '/:pathMatch(.*)*',
  component: error404Page,
  meta: {
    layout: LoginLayout
  }
})

const messages = {}
const apiModules: ApiModuleDefinitions = {}

loadModules()

export const store = createStore()
export const router = createRouter(routes)
export const i18n = createI18n(getDefaultLocale(), getFallbackLocale(), messages)
export const meta = createMeta()
export const api = createApi({ moduleDefs: apiModules })

export const initServices = (app: App) => {
  app.use(store)
  app.use(router)
  app.use(i18n)
  app.use(meta)
  app.use(api)

  app.$localeMessages = messages
  initModules(app)
}
