<template>
  <v-menu transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn
        color="default"
        v-bind="props"
        border>
        <v-icon
          icon="mdi-earth"
          size="x-large" />
        <span class="d-none d-sm-inline ml-3">{{ $t(`locales.${locale}`) }}</span>
      </v-btn>
    </template>
    <v-list min-width="200">
      <v-list-subheader>{{ $t('common.locale') }}</v-list-subheader>
      <v-list-item
        v-for="(item, i) in availableLocales"
        :key="i"
        @click="locale = item"
        :active="locale === item"
        active-color="primary">
        <v-list-item-title>{{ $t(`locales.${item}`) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useSettingsStore } from '@core/stores/settings'
import { useValidation } from '@coretechs/vue-cts-form'

export default defineComponent({
  name: 'LocaleSelect',

  setup() {
    const store = useSettingsStore()
    const { locale, availableLocales } = useI18n()
    const validation = useValidation()

    watch(locale, (newLocale) => {
      store.setLocale(newLocale)
      validation.setLocale(newLocale)
    })

    return {
      locale,
      availableLocales
    }
  }
})
</script>
