import { ApiModule } from '@/services/api/types/module.types'
import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { Workgroup, WorkgroupListParams, WorkgroupRole } from '../models/workgroup'
import { Report, UserData } from '@project/models/project'
import { Project } from '@project/models/project'

export class WorkgroupsApi extends ApiModule {
  private readonly resource = '/workgroup'
  private readonly projectResource = '/project'
  private readonly reportResource = '/reports'

  public list(params: WorkgroupListParams): Promise<ApiHalResponse<{ project: Project[] }>> {
    return this.api.get(`${this.projectResource}`, { params })
  }

  public get(id: number): Promise<ApiHalResponse<Workgroup>> {
    return this.api.get(`${this.resource}/${id}`)
  }

  public create(data: Partial<Workgroup>): Promise<ApiHalResponse<Workgroup>> {
    return this.api.post(`${this.resource}`, data)
  }

  public update(id: number, data: Partial<Workgroup>[]): Promise<ApiHalResponse<Workgroup>> {
    return this.api.put(`${this.resource}/${id}`, data)
  }

  public delete(id: number): Promise<ApiResponse> {
    return this.api.delete(`${this.resource}/${id}`)
  }

  public charts(id: number): Promise<ApiHalResponse<Report>> {
    return this.api.get(`${this.reportResource}/workgroup/${id}`)
  }

  public users(id: number, workgroupAdmin?: boolean): Promise<ApiHalResponse<{ users: UserData[] }>> {
    return this.api.get(`${this.resource}/${id}/user`, { params: { workgroupAdmin } })
  }

  public setUser(id: number, user: { userID: number; roleID: WorkgroupRole }): Promise<ApiHalResponse> {
    return this.api.post(`${this.resource}/${id}/users`, { user })
  }

  public updateUser(id: number, userID: number, roleID: WorkgroupRole): Promise<ApiHalResponse> {
    return this.api.put(`${this.resource}/${id}/users`, { userID, roleID })
  }
}
