export default {
  workgroup: {
    actions_cancel: 'Bezárás',
    actions_modify: 'Módosítás',
    field_name: 'Munkacsoport',
    field_user_name: 'Felhasználó neve',
    field_status: 'Státusz',
    field_education: 'Oktatás',
    field_research: 'Kutatás',
    field_talentCare: 'Tehetséggondozás',
    field_project: 'Projekt',
    field_sumPoints: 'Összesen',
    field_ranking: 'Munkacsoporton belüli rangsor',
    field_projectRanking: 'Projekten belüli rangsor',
    field_categories: 'Kategóriák',
    field_points: 'Pontok',
    field_workgroupPercent: 'Munkacsoporthoz való hozzájárulás',
    field_point: 'Pontszám',
    field_total_user: 'Létszám',
    field_workgroup_role: 'Szerepkör',
    tab_user_data: 'Felhasználók eredményei',
    tab_results: 'Kimuatatások',
    tab_person_sum: 'Összesített eredmények',
    tab_dimension_points: 'Dimenziónkénti eredmények',
    modal_role: 'Szerepkör módosítás',
    modal_create_user: 'Felhasználó hozzáadása',
    modal_update_user: 'Felhasználó módosítása',
    user_fill: 'Felhasználó értékelésének kitöltése',
    workgroup_data: 'Munkacsoport adatok',
    role_colleague: 'Munkavállaló',
    role_chief: 'Munkacsoport vezető',
    status: {
      opened: 'Elkezdve',
      notStarted: 'Nincs elkezdve',
      processed: 'Feldolgozva',
      closed: 'Lezárt'
    },
    modal: {
      modify: 'Munkacsoport szerkesztése',
      create: 'Munkacsoport létrehozása'
    }
  }
}
