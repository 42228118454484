import { ApiModule } from '@/services/api/types/module.types'
import { ApiResponse } from '@/services/api/types/api.types'

export class ImportApi extends ApiModule {
  private readonly resource = '/import'

  public import(file: FormData): Promise<ApiResponse> {
    return this.api.post(`${this.resource}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
  }
}
