import { User, UserRoles } from '@auth/models/user'

/**
 * Returns true if the user has the given permission
 *
 * @param user
 * @param permission
 */
export function checkUserPermission(user: User, permission: string) {
  return user.permissions?.includes(permission)
}

/**
 * Returns true if the user has the given role
 *
 * If multiple roles passed, checks if any of them matches the user's role.
 * When inheritMode is enabled, returns true if the user has the given role or any role above it.
 *
 * @param user
 * @param role
 * @param inheritMode
 */
export function checkUserRole(user: User, role: UserRoles | UserRoles[], inheritMode = false) {
  if (!Array.isArray(role) && inheritMode) {
    const roleList = Object.values(UserRoles)
    const index = roleList.findIndex((_) => _ === user.roleID)
    const filteredRoleList = roleList.slice(0, index + 1)

    return filteredRoleList.includes(role)
  }

  const checkedRoles = Array.isArray(role) ? role : [role]

  return checkedRoles.includes(user.roleID)
}
