import 'vuetify/styles'
import '@mdi/font/scss/materialdesignicons.scss'
import { createVuetify as createVueVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'
import { i18n } from '@/services'

export const createVuetify = () => {
  return createVueVuetify({
    components,
    directives,
    locale: createVueI18nAdapter({
      i18n,
      useI18n
    }),
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi
      }
    },
    theme: {
      defaultTheme: 'light',
      themes: {
        light: {
          colors: {
            background: '#FAFAFA',
            'on-surface-variant': '#FFFFFF',
            primary: '#C52E3E',
            'loading-overlay': '#FFFFFF',
            'loading-overlay-content': '#000000',
            'overlay-card-background': '#FFFFFF',
            'file-input-color': '#6200ea'
          },
          variables: {
            'loading-opacity': 0.75
          }
        },
        dark: {
          dark: true,
          colors: {
            primary: '#C52E3E',
            'loading-overlay': '#000000',
            'loading-overlay-content': '#FFFFFF',
            'overlay-card-background': '#212121',
            'file-input-color': '#FFFFFF'
          },
          variables: {
            'loading-opacity': 0.6
          }
        }
      }
    }
  })
}
