import { plugin as MetaPlugin } from 'vue-meta'
import { Module } from '@/types/module.types'
import locales from './i18n'
import { SetInitialLocale } from '@core/plugins/SetInitialLocale'

export default {
  name: 'core',
  routes: [],
  locales: locales,
  plugins: [MetaPlugin, SetInitialLocale]
} as Module
