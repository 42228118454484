import { createApp } from 'vue'
import App from './App.vue'
import { initServices } from './services'
import './assets/style/app.scss'

const app = createApp(App)
initServices(app)
app.mount('#app')

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $attrs: Record<string, never>
  }

  export interface App {
    $localeMessages: Record<string, never>
  }
}
