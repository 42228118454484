export default {
  pillars: {
    field_name: 'Pillér neve',
    field_identifier: 'Pillér azonosítója',
    field_percent: 'Százalék',
    field_educationPoint: 'Oktatás pontszám',
    field_educationPercent: 'Oktatás százalék',
    field_researchPoint: 'Kutatás pontszám',
    field_researchPercent: 'Kutatás százalék',
    field_talentCarePoint: 'Tehetséggondozás pontszám',
    field_talentCarePercent: 'Tehetséggondozás százalék',
    field_projectPoint: 'Projekt pontszám',
    field_projectPercent: 'Projekt százalék',
    actions_view: 'Pillér adatai',
    actions_results: 'Pillér eredményei',
    chart_sum_point_title: 'Pillér munkacsoportjainak teljesítménye egymáshoz viszonytva',
    chart_sum_percent_title: 'Pillérhez teljesítményéhez való hozzájárulás',
    breadcrumb_pillar_result: 'Pillér eredményei',
    breadcrumb_pillars: 'Pillérek'
  }
}
