import { ApiModule } from '@/services/api/types/module.types'
import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { AuthenticationData, LoginData } from '@auth/models/auth'
import { User } from '@auth/models/user'

export class AuthApi extends ApiModule {
  private readonly resource = '/auth'

  public login(data?: LoginData): Promise<ApiResponse<AuthenticationData>> {
    return this.api.post(`${this.resource}/login`, data)
  }

  public logout(): Promise<ApiResponse> {
    return this.api.post(`${this.resource}/logout`)
  }

  public me(): Promise<ApiHalResponse<User>> {
    return this.api.get(`${this.resource}/me`)
  }
}
