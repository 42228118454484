import { App } from 'vue'
import { Module } from '@/types/module.types'
import { createVuetify } from './services/vuetify'

export default {
  name: 'vuetify',
  init: (app: App) => {
    const vuetify = createVuetify()
    app.use(vuetify)
  }
} as Module
