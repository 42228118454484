<template>
  <v-list-item
    v-if="shouldRender"
    :title="$t(title)"
    :prepend-icon="icon"
    :to="to" />
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { RouteLocationNamedRaw } from 'vue-router'
import { UserRoles } from '@auth/models/user'
import { useUserStore } from '@auth/stores/user.store'
import appConfig from '@/config/app.config'

export default defineComponent({
  name: 'NavigationItem',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    to: {
      type: [String, Object] as PropType<string | RouteLocationNamedRaw>,
      required: false
    },
    role: {
      type: [String, Array] as PropType<UserRoles | UserRoles[]>,
      required: false
    },
    permission: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useUserStore()
    const shouldRender = computed(() => {
      if (!appConfig.authEnabled || (!props.permission && !props.role)) return true
      let result = true
      result = result && (!props.role || store.checkUserRole(props.role, true))
      result = result && (!props.permission || store.checkUserPermission(props.permission))
      return result
    })

    return {
      shouldRender
    }
  }
})
</script>
