import { ApiModule } from '@/services/api/types/module.types'
import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { Dimension } from '@dimension/models/dimension'
export class DimensionApi extends ApiModule {
  private readonly resource = '/dimension'

  public get(id: number, userID: number, projectID: string): Promise<ApiHalResponse<Dimension>> {
    return this.api.get(`${this.resource}/${id}`, {
      params: { userID: userID, projectID: projectID }
    })
  }

  public list(providedData?: boolean, userID?: number): Promise<ApiHalResponse<{ dimension: Dimension[] }>> {
    return this.api.get(`${this.resource}`, { params: { providedData, userID } })
  }

  public create(data: Dimension): Promise<ApiResponse<Dimension>> {
    return this.api.post(`${this.resource}`, data)
  }

  public update(data: Dimension): Promise<ApiResponse<Dimension>> {
    return this.api.put(`${this.resource}`, data)
  }

  public sendGroup(dataID: number, groupID: number): Promise<ApiHalResponse> {
    return this.api.post(`${this.resource}/change-input`, { dataID, groupID })
  }
}
