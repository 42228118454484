export default {
  pages: {
    index: 'Kezdőlap',
    workgroups: 'Munkacsoportjaim',
    fills: 'Kitöltéseim',
    projects: 'Projektjeim',
    pillars: 'Pillérek',
    admin: {
      workgroups: 'Munkacsoportok',
      users: 'Felhasználók',
      projects: 'Projektek',
      import: 'Adatok importálása'
    }
  },

  dialog: {
    yes: 'Igen',
    no: 'Nem',
    confirm_title_delete: 'Törlés',
    confirm_content_delete: 'Biztos, hogy törli?'
  },

  ter: {
    datatable: {
      actions: 'Műveletek'
    }
  },

  common: {
    backToIndex: 'Vissza a kezdőlapra'
  },

  navigation: {
    userSpace: 'Felhasználói menü',
    managerSpace: 'Vezetői menü',
    adminSpace: 'Adminisztráció'
  },

  error: {
    error404: 'A keresett oldal nem található.',
    error403: 'Nincs jogosultságod az oldal betöltéséhez.',
    error500: 'Hiba történt az oldal betöltése során.'
  },

  $vuetify: {
    pagination: {
      ariaLabel: {
        root: 'gyökér',
        first: 'első',
        previous: 'előző',
        next: 'következő',
        last: 'utolsó',
        currentPage: 'aktuális oldal',
        page: 'lap'
      }
    }
  },

  dataTable: {
    search: {
      label: 'Keresés a következőkben: '
    }
  },

  pagination: {
    itemsPerPage: 'Oldalankénti elemszám'
  },

  filter: {
    reset: 'Szűrő törlése',
    search: 'Szűrés',
    title: 'Keresés és szűrés'
  },

  chart: {
    person: 'Egyéni teljesítmények összpontszáma a munkacsoporthoz mérten',
    workgroup_percent: 'A munkacsoport vállalásokhoz való hozzájárulása (%)',
    workgroup_with_project: 'Munkacsoport teljesítménye a projektben lévő munkacsoportok átlagához viszonyítva',
    label_workgroup_performance: 'Munkacsoport teljesítmény',
    label_average_performance: 'Átlag teljesítmény',
    workgroup_person_entity: 'Munkacsoport tagjainak dimenziónkénti pontszámai'
  },

  notification: {
    upload_success: 'Sikeres hozzáadás',
    delete_success: 'Sikeres törlés',
    modify_success: 'Sikeres módosítás',
    user_has_added: 'Felhasználó már hozzá van adva a projekthez!'
  }
}
