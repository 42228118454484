import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { ApiModule } from '@/services/api/types/module.types'
import { Project, Report } from '@project/models/project'
import { ProjectListParams } from '../models/project'

export class ProjectApi extends ApiModule {
  private readonly resource = '/project'
  private readonly report = '/reports'

  public list(params?: ProjectListParams): Promise<ApiHalResponse<{ project: Project[] }>> {
    return this.api.get(`${this.resource}`, { params })
  }

  public get(projectID: number): Promise<ApiHalResponse<Project>> {
    return this.api.get(`${this.resource}/${projectID}`)
  }

  public create(project: Partial<Project>): Promise<ApiHalResponse<Project>> {
    return this.api.post(`${this.resource}`, project)
  }

  public update(id: number, project: Partial<Project>): Promise<ApiHalResponse<Project>> {
    return this.api.put(`${this.resource}/${id}`, project)
  }

  public delete(id: number): Promise<ApiResponse> {
    return this.api.delete(`${this.resource}/${id}`)
  }

  public charts(userID: number, projectID: number): Promise<ApiHalResponse<Report>> {
    return this.api.get(`${this.report}/user/${userID}`, { params: { projectID } })
  }
}
