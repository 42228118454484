<template>
  <metainfo />
  <error-layout v-if="error">
    <component
      v-if="error.component"
      :is="error.component" />
    <error-page
      v-else
      :code="error.code"
      :message="error.message" />
  </error-layout>
  <component
    v-else-if="layoutComponent"
    :is="layoutComponent">
    <router-view />
  </component>
  <notifications />
  <modals />
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue'
import { useMeta } from 'vue-meta'
import appConfig from '@/config/app.config'
import { useOptionsStore } from '@ter/stores/options.store'
import { useUserStore } from '@auth/stores/user.store'
import { useRouter } from 'vue-router'
import ErrorPage from '@core/pages/ErrorPage.vue'
import ErrorLayout from '@ter/layouts/ErrorLayout.vue'
import Notifications from '@core/components/Notifications.vue'
import Modals from '@core/components/Modals.vue'

export default defineComponent({
  name: 'App',
  components: {
    Notifications,
    Modals,
    ErrorLayout,
    ErrorPage
  },
  setup() {
    useMeta({
      base: '/',
      charset: 'utf8',
      title: appConfig.title,
      description: ''
    })

    const router = useRouter()
    const layoutComponent = computed(() => router.currentRoute.value.meta.layout)

    const userStore = useUserStore()
    const error = computed(() => userStore.getError())

    const optionsStore = useOptionsStore()
    const optionsSynced = ref(optionsStore.isFetched())
    // TODO: amíg az options nem jött vissza, addig kell egy loading. anélkül nem indulhat az app

    return {
      optionsSynced,
      layoutComponent,
      error
    }
  }
})
</script>
