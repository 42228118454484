<template>
  <v-app :theme="theme">
    <top-bar />
    <navigation />
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import TopBar from '../components/TopBar.vue'
import Navigation from '../components/Navigation.vue'
import { useSettingsStore } from '@core/stores/settings'

export default defineComponent({
  name: 'MainLayout',
  components: {
    TopBar,
    Navigation
  },
  setup() {
    const store = useSettingsStore()
    const theme = computed(() => store.getTheme)

    return {
      theme
    }
  }
})
</script>
