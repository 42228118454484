export default {
  user: {
    field_userID: 'Azonosító',
    field_email: 'E-mail cím',
    field_username: 'Felhasználónév',
    field_firstName: 'Keresztnév',
    field_lastName: 'Vezetéknév',
    field_status: 'Státusz',
    field_roleID: 'Szerepkör',
    field_permissions: 'Jogosultságok',
    status_active: 'Aktív',
    status_inactive: 'Inaktív',
    role_guest: 'Vendég',
    role_user: 'Felhasználó',
    role_workgroupAdmin: 'Munkacsoport vezető',
    role_admin: 'Adminisztrátor',

    create: {
      modalTitle: 'Felhasználó létrehozása',
      modalSubtitle: 'Új felhasználó'
    },
    edit: {
      modalTitle: 'Felhasználó szerkesztése'
    }
  }
}
