import { RouteRecordRaw } from 'vue-router'
import LoginLayout from '@ter/layouts/LoginLayout.vue'

export enum Routes {
  LOGIN = 'login'
}

export default [
  {
    path: '/login',
    name: Routes.LOGIN,
    component: () => import('../pages/LoginPage.vue'),
    meta: {
      layout: LoginLayout
    }
  }
] as RouteRecordRaw[]
