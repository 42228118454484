export default {
  import: {
    title: 'Data import from file',
    clear: 'Delete logs',
    error: 'File upload is required',
    field_input: 'File upload',
    field_type: 'Import type',
    save: 'Save',
    select_workgroup: 'Workgroup',
    select_project: 'Project',
    select_user: 'User'
  }
}
