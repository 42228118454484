import { Module } from '@/types/module.types'
import routes from './routes'
import locales from './i18n'
import workgroupModules from './api'

export default {
  name: 'projects',
  routes: routes,
  locales: locales,
  apiModules: workgroupModules
} as Module
