import { defineStore } from 'pinia'
import { Modal } from '@core/types/modal'
import { Component, markRaw } from 'vue'

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    modals: [] as Modal[]
  }),
  getters: {
    getModals: (state) => () => state.modals
  },
  actions: {
    open(component: Component, props?: { [key: string]: unknown }, events?: { [key: string]: (...params: never[]) => void }) {
      this.modals.push({
        component: markRaw(component),
        props,
        events,
        open: true
      })
    },
    clear() {
      this.modals.splice(0)
    }
  }
})
