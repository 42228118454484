import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiPlugin } from '@/services/api/types/plugin.types'
import appConfig from '@/config/app.config'

export class LoggerPlugin extends ApiPlugin {
  initialize(axios: AxiosInstance): void {
    if (appConfig.debugMode) {
      axios.interceptors.request.use((config: AxiosRequestConfig) => {
        console.log('api request:', config)
        return config
      })

      axios.interceptors.response.use(
        (response: AxiosResponse) => {
          console.log('api response:', response)
          return response
        },
        (error: AxiosError) => {
          console.log('api error:', error)
          return Promise.reject(error)
        }
      )
    }
  }
}

export default new LoggerPlugin()
