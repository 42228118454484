export default {
  project: {
    status: {
      active: 'Active',
      inactive: 'Inactive'
    },
    started: {
      yes: 'Yes',
      no: 'No'
    },
    actions_filled: 'Filling',
    actions_workgroupData: 'Workgroup datas',
    actions_results: 'Filling view',
    field_name: 'Project name',
    field_identifier: 'Project identifier',
    field_status: 'Status',
    field_filled: 'Started',
    field_period: 'Project period',
    field_periodFrom: 'Period-from',
    field_periodTo: 'Period-to',
    field_completion: 'Completion period',
    field_completionFrom: 'Completion-from',
    field_completionTo: 'Completion-to',
    field_percent: 'Filling status',
    field_completion_period_to: 'Filling period end',
    field_completion_period_from: 'Filling period start',
    field_period_to: 'Project period end',
    field_period_from: 'Project period start',
    field_budget: 'Budget',
    field_affiliation: 'Affiliation',
    field_description: 'Project description',
    field_input_name: 'Input name',
    field_unit: 'Unit (unit or person)',
    field_point_per_unit: 'Point / unit',
    field_point: 'Points',
    field_result_not_found: 'In this dimension has not results',
    modal_update: 'Edit project',
    modal_create: 'Add project',
    confirm_delete_title: 'Delete project',
    confirm_delete_content: 'Are you sure you are breaking the project?',
    filled_status: {
      active: 'Active',
      inactive: 'Inactive'
    },
    chart: {
      personal_performance: 'Own performance',
      workgroup_performance: 'Workgroup performance',
      average_performance: 'Average performance',
      personal_with_sum: 'Your own performance compared to Total score',
      personal_with_workgroup: 'Own performance compared to the work group',
      personal_with_workgroup_subtitle: "Own performance per dimension compared to the work group's performance per dimension.",
      personal_with_workgroup_subtitle_all: "Own performance per dimension compared to the work group's performance per dimension. (All users)",
      no_data: 'The project does not yet have a statement'
    }
  }
}
