import { Module } from '@/types/module.types'
import routes from './routes'
import locales from './i18n'
import apiModules from './api'
import { RouterGuard } from './plugins/RouterGuard'

export default {
  name: 'auth',
  routes: routes,
  locales: locales,
  plugins: [RouterGuard],
  apiModules: apiModules
} as Module
