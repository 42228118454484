<template>
  <v-btn
    :icon="theme === 'light' ? 'mdi-weather-night' : 'mdi-weather-sunny'"
    @click="switchTheme"
    class="mr-2" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useSettingsStore } from '@core/stores/settings'

export default defineComponent({
  name: 'ThemeSelect',
  setup() {
    const store = useSettingsStore()
    const theme = computed(() => store.getTheme)
    const switchTheme = () => {
      store.setTheme(theme.value === 'light' ? 'dark' : 'light')
    }

    return {
      theme,
      switchTheme
    }
  }
})
</script>
