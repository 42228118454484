import { RouteRecordRaw } from 'vue-router'
import MainLayout from '@ter/layouts/MainLayout.vue'

export enum Routes {
  IMPORT = 'import'
}

export default [
  {
    path: '/admin/import',
    name: Routes.IMPORT,
    props: true,
    component: () => import('../pages/ImportPage.vue'),
    meta: {
      permission: 'chief.permission',
      layout: MainLayout
    }
  }
] as RouteRecordRaw[]
