export default {
  user: {
    field_userID: 'ID',
    field_email: 'E-mail address',
    field_username: 'Username',
    field_firstName: 'First name',
    field_lastName: 'Last name',
    field_status: 'Status',
    field_roleID: 'Role',
    field_permissions: 'Permissions',
    status_active: 'Active',
    status_inactive: 'Inactive',
    role_guest: 'Guest',
    role_user: 'User',
    role_workgroupAdmin: 'Workgroup administrator',
    role_admin: 'Administrator',

    create: {
      modalTitle: 'Create user',
      modalSubtitle: 'Add user'
    },
    edit: {
      modalTitle: 'Edit user'
    }
  }
}
