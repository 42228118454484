import { ApiModule } from '@/services/api/types/module.types'
import { ApiResponse } from '@/services/api/types/api.types'
import { Options } from '../models/options'

export class OptionsApi extends ApiModule {
  private readonly resource = '/options'

  public get(): Promise<ApiResponse<Options>> {
    return this.api.get(`${this.resource}`)
  }
}
