import { Module } from '@/types/module.types'
import locales from './i18n'
import routes from './routes'
import dimensionModules from './api'

export default {
  name: 'dimension',
  routes: routes,
  locales: locales,
  apiModules: dimensionModules
} as Module
