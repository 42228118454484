export default {
  project: {
    status: {
      active: 'Aktív',
      inactive: 'Inaktív'
    },
    started: {
      yes: 'Igen',
      no: 'Nem'
    },
    actions_filled: 'Értékelésem',
    actions_workgroupData: 'Munkacsoport adatok',
    actions_results: 'Értékelésem megtekintése',
    field_name: 'Projekt neve',
    field_identifier: 'Projekt Azonosító',
    field_status: 'Kitöltés státusz',
    field_filled: 'Elkezdve',
    field_period: 'Projekt időszak',
    field_completion: 'Bevallási időszak',
    field_periodFrom: 'Kitöltési időszak kezdete',
    field_periodTo: 'Kitöltési időszak vége',
    field_completionFrom: 'Teljesítési időszak kezdete',
    field_completionTo: 'Teljesítési időszak vége',
    field_percent: 'kitöltés állapota',
    field_completion_period_to: 'Kitöltési időszak vége',
    field_completion_period_from: 'Kitöltési időszak kezdete',
    field_period_to: 'Projekt időszak vége',
    field_period_from: 'Projekt időszak kezdete',
    field_budget: 'Keret',
    field_affiliation: 'Affiliáció',
    field_description: 'Projekt leírása',
    field_input_name: 'Input megnevezése',
    field_unit: 'Egység (mennyiség vagy fő)',
    field_point_per_unit: 'Pont / egység',
    field_point: 'Kapott pont',
    field_result_not_found: 'A dimenzióhoz nem tartozik eredmény',
    modal_update: 'Projekt módosítása',
    modal_create: 'Projekt hozzáadás',
    confirm_delete_title: 'Projekt törlése',
    confirm_delete_content: 'Biztos, hogy töri a projektet?',
    filled_status: {
      inactive: 'Inaktív',
      active: 'Aktív'
    },
    chart: {
      personal_performance: 'Saját teljesítmény',
      workgroup_performance: 'Munkacsoport teljesítmény',
      average_performance: 'Átlag teljesítmény',
      personal_with_sum: 'Saját teljesítménye Összpontszámhoz viszonyítva',
      personal_with_workgroup: 'Saját teljesítmény a munkacsoporthoz viszonyítva',
      personal_with_workgroup_subtitle: 'Saját dimenzionkénti teljesítmény a munkacsoport dimenziónkénti teljesítményéhez viszonyítva. (Akik a pontokat összeszedték)',
      personal_with_workgroup_subtitle_all: 'Saját dimenzionkénti teljesítmény a munkacsoport dimenziónkénti teljesítményéhez viszonyítva. (Minden mcs tag)',
      no_data: 'A projekthez még nem tartozik kimutatás'
    }
  }
}
