import { User, UserRoles, UserStatus } from '@auth/models/user'
import { strictMerge } from '@/utils/obj.util'

export function createUser(data: Partial<User> = {}): User {
  const base: User = {
    userID: 0,
    email: '',
    username: '',
    firstName: 'Guest',
    lastName: '',
    data: null,
    status: UserStatus.INACTIVE,
    roleID: UserRoles.GUEST,
    permissions: []
  }

  return strictMerge(base, data)
}
