export default {
  pillars: {
    field_name: 'Pillar name',
    field_identifier: 'Pillar identifier',
    field_percent: 'Percent',
    field_educationPoint: 'Education point',
    field_educationPercent: 'Education percent',
    field_researchPoint: 'Research point',
    field_researchPercent: 'Research percent',
    field_talentCarePoint: 'Talent care point',
    field_talentCarePercent: 'Talent care percent',
    field_projectPoint: 'Project point',
    field_projectPercent: 'Project percent',
    actions_view: 'Pillar datas',
    actions_results: 'Pillar results',
    chart_sum_point_title: "The performance of Pillar's working groups in relation to Pillar",
    chart_sum_percent_title: 'Contribution to pillar performance',
    breadcrumb_pillar_result: 'Pillar results',
    breadcrumb_pillars: 'Pillars'
  }
}
