export default {
  locales: {
    en: 'Angol',
    hu: 'Magyar'
  },

  common: {
    locale: 'Nyelv',
    yes: 'Igen',
    no: 'Nem',
    submit: 'Küldés',
    reset: 'Visszaállítás',
    select: 'Kérjük válasszon..',
    save: 'Mentés',
    create: 'Létrehozás',
    edit: 'Módosítás',
    delete: 'Törlés',
    cancel: 'Mégsem',
    confirm: 'Mehet',
    confirm_modalTitle: 'Megerősítés',
    confirm_modalContent: 'Biztos, hogy elvégzed a műveletet?',
    workgroup_users: 'Munkacsoport felhasználói',
    role_change: 'Szerepkör módosítás',
    loading: 'Betöltés folyamatban!'
  },

  error: {
    unknownError: 'Ismeretlen hiba',
    networkError: 'Hálózati hiba',
    validationError: 'Kérjük ellenőrizze az űrlapon megadott adatokat'
  },

  validation: {
    messages: {
      _default: 'A(z) {field} értéke érvénytelen',
      alpha: 'A(z) {field} kizárólag betűket tartalmazhat',
      alpha_num: 'A(z) {field} kizárólag betűket és számokat tartalmazhat',
      alpha_dash: 'A(z) {field} kizárólag betűket, számokat, kötőjeleket és alulvonásokat tartalmazhat',
      alpha_spaces: 'A(z) {field} kizárólag betűket és szóközöket tartalmazhat',
      between: 'A(z) {field} 0:{min} és 1:{max} között kell, hogy legyen',
      confirmed: 'A(z) {field} nem egyezik a megerősítéssel',
      digits: 'A(z) {field} 0:{length} számjegyű kell, hogy legyen',
      dimensions: 'A(z) {field} felbontása 0:{width} és 1:{height} pixel között kell, hogy legyen',
      email: 'A(z) {field} nem érvényes email formátum',
      excluded: 'A(z) {field} értéke érvénytelen',
      ext: 'A(z) {field} nem érvényes fájl',
      image: 'A(z) {field} képfálj kell, hogy legyen',
      integer: 'A(z) {field} értéke egész szám kell, hogy legyen',
      length: 'A(z) {field} értéke pontosan 0:{length} karaktert kell, hogy tartalmazzon',
      max_value: 'A(z) {field} értéke 0:{max} vagy kevesebb lehet',
      max: 'A(z) {field} értéke nem lehet hosszabb mint 0:{length}',
      mimes: 'A(z) {field} kizárólag érvényes fájlformátumok egyike lehet',
      min_value: 'A(z) {field} értéke 0:{min} vagy több lehet',
      min: 'A(z) {field} értéke nem lehet rövidebb mint 0:{length}',
      numeric: 'A(z) {field} értéke szám kell, hogy legyen',
      one_of: 'A kiválaszott {field} érvénytelen',
      regex: 'A(z) {field} formátuma érvénytelen',
      required_if: 'A(z) {field} megadása kötelező',
      required: 'A(z) {field} megadása kötelező',
      size: 'A(z) {field} méretének 0:{size} kilobájtnál kisebbnek kell lennie',
      url: 'A(z) {field} értéke nem érvényes URL',
      in_array: 'A(z) {field} értéke érvénytelen',
      terms_required: 'Kötelező elfogadnod az általános szerződési feltételeket'
    }
  }
}
