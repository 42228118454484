import { RouteRecordRaw } from 'vue-router'
import MainLayout from '@ter/layouts/MainLayout.vue'

export enum Routes {
  USERS = 'admin.users'
}

export default [
  {
    path: '/admin/users',
    name: Routes.USERS,
    component: () => import('../pages/UserPage.vue'),
    meta: {
      layout: MainLayout,
      permission: 'admin.permission'
    }
  }
] as RouteRecordRaw[]
