<template>
  <v-container>
    <v-card elevation="2">
      <v-list-item class="flex-column pa-10 text-center">
        <h1 class="text-xl-h1 mb-10">{{ code }}</h1>
        <h3 v-html="$t(message)" />
      </v-list-item>
      <v-card-actions class="justify-center mt-5">
        <slot>
          <v-btn
            color="primary"
            :to="{ name: 'index' }">
            {{ $t('common.backToIndex') }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ErrorPage',
  components: {},
  props: {
    code: {
      type: Number,
      default: 404
    },
    message: {
      type: String,
      default: 'error.error404'
    }
  }
})
</script>
