export default {
  dimension: {
    actions_next: 'Következő',
    actions_prev: 'Előző',
    actions_save: 'Mentés',
    not_form: 'Ehhez a dimenzióhoz nem tartozik form!',
    fill_success: 'Sikeres mentés',
    fill_invalid: 'Sikertelen mentés! Töltsön ki minden kötelező mezőt!',
    field_research: 'Kutatás',
    field_education: 'Oktatás',
    field_talent_care: 'Tehetséggondozás',
    field_project: 'Projekt',
    btn_not_classified: 'be nem sorolt adatok'
  }
}
