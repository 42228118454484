import { RouteRecordRaw } from 'vue-router'
import MainLayout from '../layouts/MainLayout.vue'

export enum Routes {
  INDEX = 'index'
}

export default [
  {
    path: '/',
    name: Routes.INDEX,
    component: () => import('../pages/IndexPage.vue'),
    meta: {
      layout: MainLayout,
      permission: 'user.permission'
    }
  }
] as RouteRecordRaw[]
