import { Plugin } from 'vue'
import { RouteLocationNormalized } from 'vue-router'
import { router } from '@/services'
import { Routes as AuthRoutes } from '@auth/routes'
import { Routes as TerRoutes } from '@ter/routes'
import { useUserStore } from '@auth/stores/user.store'
import Error403Page from '@auth/pages/Error403Page.vue'
import appConfig from '@/config/app.config'

export const RouterGuard: Plugin = {
  async install() {
    router.beforeEach(async (to: RouteLocationNormalized) => {
      if (!appConfig.authEnabled) {
        return true
      }

      const store = useUserStore()
      const user = await store.fetchUser()
      const token = store.getToken()

      store.setError(null) // clear errors

      if (to.name === AuthRoutes.LOGIN && token && user) {
        return { name: TerRoutes.INDEX }
      }

      if (to.meta.permission) {
        if (!user || !token) {
          return { name: AuthRoutes.LOGIN }
        }

        if (!user.permissions?.includes(to.meta.permission)) {
          store.setError({
            component: Error403Page
          })
        }
      }

      return true
    })
  }
}
