import { RouteRecordRaw } from 'vue-router'
import MainLayout from '@ter/layouts/MainLayout.vue'

export enum Routes {
  PILLARS = 'pillars',
  REPORT = 'pillar.report'
}

export default [
  {
    path: '/pillars',
    name: Routes.PILLARS,
    component: () => import('../pages/PillarPage.vue'),
    meta: {
      permission: 'admin.permission',
      layout: MainLayout
    }
  },
  {
    path: '/pillars/:pillar_id',
    name: Routes.REPORT,
    component: () => import('../pages/PillarReport.vue'),
    meta: {
      permission: 'admin.permission',
      layout: MainLayout
    }
  }
] as RouteRecordRaw[]
