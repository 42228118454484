export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum UserRoles {
  GUEST = 'guest',
  USER = 'user',
  WORKGROUP_ADMIN = 'chief',
  ADMIN = 'admin'
}

export interface UserData {
  [key: string]: never
}

export interface User {
  userID: number
  email: string
  username: string
  firstName: string
  lastName: string
  data?: UserData
  status: UserStatus
  roleID: UserRoles
  permissions: string[]
}
