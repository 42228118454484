import { ApiModule } from '@/services/api/types/module.types'
import { ApiHalResponse } from '@/services/api/types/api.types'
import { Form, ValidForm } from '../models/forms'
import { StoredFill, UserHasFill } from '@dimension/models/dimension'

export class FormsApi extends ApiModule {
  private readonly resource = '/forms'
  private readonly fillResource = '/fill'

  public get(ids: number[]): Promise<ApiHalResponse<{ form: Form[] }>> {
    return this.api.get(`${this.resource}`, { params: { formID: [...ids] } })
  }

  public createFill(data: ValidForm, dimensionID: number, form: Form, projectID: number, userID: number): Promise<ApiHalResponse<UserHasFill>> {
    return this.api.post(`${this.fillResource}`, {
      values: data.values,
      hashes: data.fieldHashMap,
      form: data.form,
      formID: data.form.formID,
      dimensionID: dimensionID,
      formPrototype: form,
      projectID: projectID,
      userID: userID
    })
  }

  public getFill(projectID: number, formID: number, dimensionID: number, userID: number): Promise<ApiHalResponse<StoredFill>> {
    return this.api.get(`${this.fillResource}`, { params: { projectID, formID, dimensionID, userID } })
  }
}
