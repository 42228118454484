import { Module } from '@/types/module.types'
import locales from './i18n'
import routes from './routes'
import pillarModules from './api'

export default {
  name: 'pillar',
  routes: routes,
  locales: locales,
  apiModules: pillarModules
} as Module
