import { AxiosInstance } from 'axios'
import { ApiPlugin } from '@/services/api/types/plugin.types'
import { useNotificationStore } from '@core/stores/notification'
import { ApiError, ApiResponse } from '@/services/api/types/api.types'

export class ErrorNotificationPlugin extends ApiPlugin {
  initialize(axios: AxiosInstance): void {
    axios.interceptors.response.use(
      (response: ApiResponse) => response,
      (error: ApiError) => {
        const store = useNotificationStore()
        store.addException(error, true)

        return Promise.reject(error)
      }
    )
  }
}

export default new ErrorNotificationPlugin()
