import appConfig from '@/config/app.config'

export const isLocaleSupported = (locale: string) => {
  return appConfig.locales.includes(locale)
}

export const getBrowserLocale = (options = {}) => {
  const defaultOptions = { countryCodeOnly: false }
  const opt = { ...defaultOptions, ...options }
  const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language

  if (!navigatorLocale) {
    return undefined
  }

  return opt.countryCodeOnly ? navigatorLocale.trim().split(/[-_]/)[0] : navigatorLocale.trim()
}

export const getDefaultLocale = (): string => {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true })

  if (!!browserLocale && isLocaleSupported(browserLocale)) {
    return browserLocale
  }

  return isLocaleSupported(appConfig.defaultLocale) ? appConfig.defaultLocale : appConfig.locales[0] || 'en'
}

export const getFallbackLocale = (): string => {
  return isLocaleSupported(appConfig.fallbackLocale) ? appConfig.fallbackLocale : appConfig.locales[0] || 'en'
}
