import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { ApiPlugin } from '@/services/api/types/plugin.types'
import { ApiError, ApiResponse } from '@/services/api/types/api.types'

export class ApiResponsePlugin extends ApiPlugin {
  initialize(axios: AxiosInstance): void {
    axios.interceptors.response.use(
      <T>(response: AxiosResponse) => {
        return {
          headers: response.headers || undefined,
          status: response.status || undefined,
          data: response.data || undefined
        } as ApiResponse<T>
      },
      (error: AxiosError) => {
        return Promise.reject({
          headers: error.response?.headers,
          status: error.response?.status,
          statusText: error.response?.statusText,
          message: error.message,
          code: error.code,
          data: error.response?.data,
          axiosError: error
        } as ApiError)
      }
    )
  }
}

export default new ApiResponsePlugin()
