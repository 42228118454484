<template>
  <template v-if="shouldRender">
    <v-list-subheader v-if="title">{{ $t(title) }}</v-list-subheader>
    <slot />
  </template>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useUserStore } from '@auth/stores/user.store'
import appConfig from '@/config/app.config'
import { UserRoles } from '@auth/models/user'

export default defineComponent({
  name: 'NavigationSection',
  components: {},
  props: {
    title: {
      type: String,
      required: false
    },
    role: {
      type: [String, Array] as PropType<UserRoles | UserRoles[]>,
      required: false
    },
    permission: {
      type: String,
      required: false
    }
  },
  setup(props) {
    const store = useUserStore()
    const shouldRender = computed(() => {
      if (!appConfig.authEnabled || (!props.permission && !props.role)) return true
      let result = true
      result = result && (!props.role || store.checkUserRole(props.role, true))
      result = result && (!props.permission || store.checkUserPermission(props.permission))
      return result
    })

    return {
      shouldRender
    }
  }
})
</script>
