import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { ApiModule } from '@/services/api/types/module.types'
import { Project, Report } from '@project/models/project'
import { PillarListParams } from '../models/pillars'

export class PillarApi extends ApiModule {
  private readonly resource = '/pillar'
  private readonly reportResource = '/reports'

  public list(params: PillarListParams): Promise<ApiHalResponse<{ pillar: Project[] }>> {
    return this.api.get(`${this.resource}`, { params })
  }

  public get(pillarID: number): Promise<ApiHalResponse<Project>> {
    return this.api.get(`${this.resource}/${pillarID}`)
  }

  public create(pillar: Partial<Project>): Promise<ApiHalResponse<Project>> {
    return this.api.post(`${this.resource}`, pillar)
  }

  public update(id: number, pillar: Partial<Project>): Promise<ApiHalResponse<Project>> {
    return this.api.put(`${this.resource}/${id}`, pillar)
  }

  public delete(id: number): Promise<ApiResponse> {
    return this.api.delete(`${this.resource}/${id}`)
  }

  public charts(id: number): Promise<ApiHalResponse<Report>> {
    return this.api.get(`${this.reportResource}/pillar/${id}`)
  }
}
