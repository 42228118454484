import { ApiModule } from '@/services/api/types/module.types'
import { ApiHalResponse, ApiResponse } from '@/services/api/types/api.types'
import { User } from '@auth/models/user'
import { UserListParams } from '@user/models/user'

export class UserApi extends ApiModule {
  private readonly resource = '/user'

  public list(params: UserListParams = {}): Promise<ApiHalResponse<{ user: User[] }>> {
    return this.api.get(`${this.resource}`, { params })
  }

  public get(id: number): Promise<ApiHalResponse<User>> {
    return this.api.get(`${this.resource}/${id}`)
  }

  public create(data: Partial<User>): Promise<ApiHalResponse<User>> {
    return this.api.post(`${this.resource}`, data)
  }

  public update(id: number, data: Partial<User>): Promise<ApiHalResponse<User>> {
    return this.api.put(`${this.resource}/${id}`, data)
  }

  public delete(id: number): Promise<ApiResponse> {
    return this.api.delete(`${this.resource}/${id}`)
  }
}
