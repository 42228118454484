<template>
  <v-app-bar
    app
    class="main-header"
    elevation="1"
    color="black">
    <v-app-bar-nav-icon
      v-if="showNavSwitch"
      variant="text"
      @click.stop.prevent="switchNav()" />
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <theme-select />
    <locale-select />
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import appConfig from '@/config/app.config'
import { useSettingsStore } from '@core/stores/settings'
import LocaleSelect from './LocaleSelect.vue'
import ThemeSelect from './ThemeSelect.vue'

export default defineComponent({
  name: 'TopBar',
  components: {
    ThemeSelect,
    LocaleSelect
  },
  props: {
    showNavSwitch: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    const store = useSettingsStore()
    const switchNav = () => store.setNavOpen(!store.getNavOpen())

    return {
      title: appConfig.title,
      switchNav
    }
  }
})
</script>

<style lang="scss">
.main-header {
  .logo {
    max-width: 32px;
  }
}
</style>
