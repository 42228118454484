export default {
  auth: {
    login: 'Login',
    logout: 'Logout'
  },

  error: {
    error403: "You don't have permission to the requested page."
  }
}
