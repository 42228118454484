export default {
  dimension: {
    actions_next: 'Next',
    actions_prev: 'Previous',
    actions_save: 'Save',
    not_form: 'This dimension does not have a form!',
    fill_success: 'Save success',
    fill_invalid: 'Save failed! Fill all required fields',
    field_research: 'Research',
    field_education: 'Education',
    field_talent_care: 'Talent care',
    field_project: 'Project',
    btn_not_classified: 'Unclassified datas'
  }
}
