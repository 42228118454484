<template>
  <v-dialog
    v-for="(modal, i) in modals"
    :key="i"
    v-model="modal.open"
    persistent
    :fullscreen="isMobile"
    :class="theme === 'dark' ? 'v-theme--dark' : ''">
    <component
      :is="modal.component"
      v-bind="modal.props"
      v-on="modal.events"
      @close="closeModal(modal)" />
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, watch, computed } from 'vue'
import { useModalStore } from '@core/stores/modal'
import { Modal } from '@core/types/modal'
import { useDisplay } from 'vuetify'
import { useSettingsStore } from '@core/stores/settings'

export default defineComponent({
  name: 'Modals',
  setup() {
    const display = useDisplay()
    const store = useModalStore()
    const modals = store.getModals()
    const settingsStore = useSettingsStore()
    const theme = computed(() => settingsStore.getTheme)

    watch(
      modals,
      () => {
        const openedModals = modals.filter((modal) => modal.open)
        if (!openedModals.length) {
          setTimeout(() => {
            store.clear()
          }, 300)
        }
      },
      { deep: true }
    )

    const closeModal = (modal: Modal) => {
      modal.open = false
    }

    return {
      isMobile: display.smAndDown,
      modals,
      theme,
      closeModal
    }
  }
})
</script>
