export default {
  pages: {
    index: 'Index',
    workgroups: 'My workgroups',
    fills: 'My fills',
    projects: 'My projects',
    pillars: 'Pillars',
    admin: {
      workgroups: 'Workgroups',
      users: 'Users',
      projects: 'Projects',
      import: 'Data imports'
    }
  },

  dialog: {
    yes: 'Yes',
    no: 'No',
    confirm_title_delete: 'Delete',
    confirm_content_delete: 'Are you shure delete?'
  },

  ter: {
    datatable: {
      actions: 'Actions'
    }
  },

  common: {
    backToIndex: 'Back to the index page'
  },

  navigation: {
    userSpace: 'User space',
    managerSpace: 'Manager space',
    adminSpace: 'Administration'
  },

  error: {
    error404: 'The requested page is not found.',
    error403: "You don't have permission to the requested page.",
    error500: 'An error occurred while loading the page.'
  },

  $vuetify: {
    pagination: {
      ariaLabel: {
        root: 'root',
        first: 'first',
        previous: 'previous',
        next: 'next',
        last: 'last',
        currentPage: 'current page',
        page: 'page'
      }
    }
  },

  dataTable: {
    search: {
      label: 'Search in this fields: '
    }
  },

  pagination: {
    itemsPerPage: 'Items per page'
  },

  filter: {
    reset: 'Reset',
    search: 'Filter',
    title: 'Search and filter'
  },

  chart: {
    person: 'Individual performances compared to the work group',
    workgroup_percent: 'Contribution of the working group to commitments (%)',
    workgroup_with_project: 'Workgroup performance compared to the average of the workgroups in the project',
    label_workgroup_performance: 'Workgroup performance',
    label_average_performance: 'Average performance',
    workgroup_person_entity: 'Scores of work group members per dimension'
  },

  notification: {
    upload_success: 'Upload success',
    delete_success: 'Delete success',
    modify_success: 'Modify success'
  }
}
