import { Api } from '@/services/api'

export type ApiModuleDefinitions = Record<string, unknown>

export abstract class ApiModule {
  protected readonly api: Api

  constructor(api: Api) {
    this.api = api
  }
}
