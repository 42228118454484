export default {
  import: {
    title: 'Adatok importálása fájlból',
    clear: 'Log törlése',
    error: 'Fájl feltöltése kötelező!',
    field_input: 'Fájl feltöltés',
    field_type: 'Import típusa',
    save: 'Mentés',
    select_workgroup: 'Munkacsoport',
    select_project: 'Projekt',
    select_user: 'Felhasználó'
  }
}
