import { App } from 'vue'
import { Module } from '@/types/module.types'
import { createForms, createValidation } from '@coretechs/vue-cts-form'
import '@coretechs/vue-cts-form/styles'
import { i18n } from '@/services'
import apiModules from './api'

export default {
  name: 'forms',
  apiModules: apiModules,
  init: (app: App) => {
    const forms = createForms()
    const validation = createValidation({
      addDefaultRules: true,
      dictionaries: app.$localeMessages,
      dictionaryValidationKey: 'validation'
    })

    app.use(forms)
    app.use(validation)

    validation.setLocale(i18n.global.locale.value)
  }
} as Module
