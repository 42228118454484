export default {
  title: ENV_APP_TITLE || 'TKP TÉR',

  // locales
  locales: ['en', 'hu'],
  defaultLocale: ENV_APP_DEFAULT_LOCALE || 'hu',
  fallbackLocale: 'hu',

  // api
  apiUrl: ENV_APP_API_URL,
  apiCredentials: false,

  // themes
  defaultTheme: 'light',

  // authentication
  authEnabled: true,

  // notifications
  notificationsTimeout: 4000,
  notificationsPosition: 'bottom right',

  // debug
  debugMode: DEBUG_MODE
}
